import React from 'react'
import App from 'App'
import SEO from 'components/SEO'
import Footer from 'components/Footer'
import imageHeader from 'static/images/integrations/trustpilot/header_picture.webp'
import G2FeaturesComponent from 'components/G2FeaturesComponent'
import { Flex } from 'components/Layout'
import PageHeader from './components/PageHeader'
import CoreFeaturesSection from './components/CoreFeaturesSection'
import FaqSection from './components/FaqSection'
import GetStartedSection from '../GetStartedSection'
import ManageReviewsSection from './components/ManageReviewsSection'

const NETWORK = 'Trustpilot'

const IntegrationsSocial = () => {
  return (
    <App fullHeader>
      <SEO
        title={`${NETWORK} management tools`}
        description={`Manage and monitor your ${NETWORK} reviews from a unified social and review management platform.`}
        path="/integrations/trustpilot/"
      />

      <PageHeader
        network={NETWORK}
        headerText={`${NETWORK} management tools`}
        description={`Manage and monitor your ${NETWORK} reviews from a unified social and review management platform.`}
        image={imageHeader}
      />

      <ManageReviewsSection network={NETWORK} />

      <Flex mt="m" pt="m" flexDirection="column" alignItems="center" mb={{ mobile: 'l', desktop: 'l' }}>
        <G2FeaturesComponent />
      </Flex>

      <CoreFeaturesSection headerTitle={`Take your ${NETWORK} Marketing to the Next Level`} matches={[NETWORK]} />

      <FaqSection />

      <GetStartedSection />

      <Footer />
    </App>
  )
}

export default IntegrationsSocial
